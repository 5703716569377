// tslint:disable
import { useClientRect } from 'atomic/obj.custom-hooks/client-rect.hook'
import React, { useState, useEffect } from 'react'
import { Button } from '..'
import {
  SeeMoreBoxButtonWrapperStyled,
  SeeMoreBoxCollapsibleStyled,
  SeeMoreBoxContentStyled,
  SeeMoreBoxStyled,
  FADESIZE
} from './see-more-box.component.style'

export interface SeeMoreBoxProps {
  defaultExpanded?: boolean
  minHeight?: number
}

export interface SeeMoreBoxState {
  expanded: boolean
  contentHeight: number
}

export const SeeMoreBox: React.FunctionComponent<SeeMoreBoxProps> = props => {
  const [rect, ref] = useClientRect()
  const seeMoreButtonWrapperHeight = 24
  const calculatedHeight = props.minHeight - seeMoreButtonWrapperHeight
  const minHeight = calculatedHeight < FADESIZE ? FADESIZE : calculatedHeight
  const [expanded, setExpanded] = useState(props.defaultExpanded)
  const [contentHeight, setContentHeight] = useState(0)
  const shouldUseSeeMoreFunctionality = minHeight < contentHeight

  useEffect(() => {
    if (rect) {
      setContentHeight(rect.height)
    }
  })

  const handleToggle = () => {
    setExpanded(!expanded)
    setContentHeight(expanded ? minHeight : rect.height)
  }

  const content = (
    <SeeMoreBoxContentStyled ref={ref}>
      {props.children}
    </SeeMoreBoxContentStyled>
  )

  return !shouldUseSeeMoreFunctionality ? (
    content
  ) : (
    <SeeMoreBoxStyled expanded={expanded}>
      <SeeMoreBoxCollapsibleStyled
        expanded={expanded}
        contentHeight={contentHeight}
        minHeight={minHeight}
      >
        {content}
      </SeeMoreBoxCollapsibleStyled>

      {shouldUseSeeMoreFunctionality && (
        <SeeMoreBoxButtonWrapperStyled>
          <Button id={`button-see-more-box`} kind='link' onClick={handleToggle}>
            {expanded ? 'Ver menos' : 'Ver mais'}
          </Button>
        </SeeMoreBoxButtonWrapperStyled>
      )}
    </SeeMoreBoxStyled>
  )
}

SeeMoreBox.defaultProps = {
  minHeight: 0
}
