// tslint:disable

import styled from 'styled-components'
import { Color, Spacing } from '../legacy/obj.constants'

export const FADESIZE = 200
interface SeeMoreBoxCollapsibleProps {
  expanded: boolean
  contentHeight: number
  minHeight: number
}

const padding = Spacing.Large
export const SeeMoreBoxStyled = styled.div < { expanded: boolean }>`
  position: relative;
  padding-bottom: ${padding};
  background: ${Color.White};

  :after {
    content: '';
    opacity: ${(props) => (props.expanded ? 0 : 1)};
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: ${padding};
    left: 0;
    width: 100%;
    height: ${FADESIZE}px;
    background-image: linear-gradient(transparent, ${Color.White});
    z-index: 1;
  }
`

export const SeeMoreBoxCollapsibleStyled = styled.div<SeeMoreBoxCollapsibleProps>`
  max-height: ${(props) => (props.expanded ? `${props.contentHeight}px` : `${props.minHeight}px`)};
  min-height: ${FADESIZE}
  -moz-transition: max-height .3s ease-in-out;
  -ms-transition: max-height .3s  ease-in-out;
  -o-transition: max-height .3s  ease-in-out;
  -webkit-transition: max-height .3s  ease-in-out;
  transition: max-height .3s  ease-in-out;
  overflow: hidden;
`

export const SeeMoreBoxButtonWrapperStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
`

export const SeeMoreBoxContentStyled = styled.div`
  border: 1px solid transparent;
`
