import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import {
  ScrollInfo,
  TableOfContents
} from 'site/src/components/mol.table-of-contents/table-of-contents.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getPathUrl } from 'utils/path'
import { diactriclessKebabCase } from 'utils/string'
import { getClientAndDoctorUrl, isDoctorUrl } from 'utils/url'
import { Link } from '@reach/router'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import {
  CosmicjsManualDeDoencasMetadata,
  Query
} from 'site/src/data/graphql/graphql-types'
import styled, { css } from 'styled-components'

import {
  Body,
  BodyDiseaseContent,
  Button,
  Col,
  Divisor,
  Grid,
  GridSettings,
  H2,
  H3,
  Row,
  Separator
} from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { SeeMoreBox } from 'atomic/mol.see-more-box'
import { graphql } from 'gatsby'
import React from 'react'

interface SectionInfo {
  id: string
  title: string
  content: string
}

export interface DiseaseDetailTemplateProps {
  slug: string
}

const content = 'content'
const examesDiagnosticos: keyof CosmicjsManualDeDoencasMetadata =
  'examesDiagnosticos'
const causasSintomas: keyof CosmicjsManualDeDoencasMetadata = 'causasSintomas'
const tratamentosPrevencoes: keyof CosmicjsManualDeDoencasMetadata =
  'tratamentosPrevencoes'
const informacoesGestantes: keyof CosmicjsManualDeDoencasMetadata =
  'informacoesGestantes'
const outrasInformacoes: keyof CosmicjsManualDeDoencasMetadata =
  'outrasInformacoes'

class DiseaseDetailTemplate extends React.Component<
  PageProps<DiseaseDetailTemplateProps, Query>
> {
  render() {
    const data = this.props.data.cosmicjsManualDeDoencas
    const staticInfo = this.props.data.cosmicjsInformacoesEstaticas
    const breadcrumbAdditionalDictionary = {}
    breadcrumbAdditionalDictionary[`/${this.props.pageContext.slug}`] =
      data.title
    const isDoctor = isDoctorUrl(this.props.location.pathname)
    const localPath = getPathUrl(appPaths.diseaseManual.path, isDoctor)
    const [clientPath, doctorPath] = getClientAndDoctorUrl(this.props.location)

    const sections: SectionInfo[] = []
    if (data.content) {
      sections.push({ id: content, title: 'Descrição', content: data.content })
    }
    if (data.metadata.causasSintomas) {
      sections.push({
        id: diactriclessKebabCase(
          data.metafields.find(item => item.key === causasSintomas).title
        ),
        title: data.metafields.find(item => item.key === causasSintomas).title,
        content: data.metadata.causasSintomas
      })
    }
    if (data.metadata.examesDiagnosticos) {
      sections.push({
        id: diactriclessKebabCase(
          data.metafields.find(item => item.key === examesDiagnosticos).title
        ),
        title: data.metafields.find(item => item.key === examesDiagnosticos)
          .title,
        content: data.metadata.examesDiagnosticos
      })
    }
    if (data.metadata.tratamentosPrevencoes) {
      sections.push({
        id: diactriclessKebabCase(
          data.metafields.find(item => item.key === tratamentosPrevencoes).title
        ),
        title: data.metafields.find(item => item.key === tratamentosPrevencoes)
          .title,
        content: data.metadata.tratamentosPrevencoes
      })
    }
    if (data.metadata.informacoesGestantes) {
      sections.push({
        id: diactriclessKebabCase(
          data.metafields.find(item => item.key === informacoesGestantes).title
        ),
        title: data.metafields.find(item => item.key === informacoesGestantes)
          .title,
        content: data.metadata.informacoesGestantes
      })
    }
    if (data.metadata.outrasInformacoes) {
      sections.push({
        id: diactriclessKebabCase(
          data.metafields.find(item => item.key === outrasInformacoes).title
        ),
        title: data.metafields.find(item => item.key === outrasInformacoes)
          .title,
        content: data.metadata.outrasInformacoes
      })
    }

    const scrollInfo: ScrollInfo[] = sections.map(section => ({
      id: section.id,
      text: section.title
    }))

    return (
      <ClientDoctorSelectContext.Provider
        value={{ clientUrl: clientPath, doctorUrl: doctorPath }}
      >
        <IndexLayout location={this.props.location}>
          <SEO
            socialMedia={{
              canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
              title: data.metadata.seo.titulo,
              image: data.metadata.seo.imagem.url,
              imageAlt: data.metadata.seo.imagemAlternativa,
              description: data.metadata.seo.descricao
            }}
          />
          <Grid>
            <TitleWithBreadcrumbRow
              title={data.title}
              addtionalDictionary={breadcrumbAdditionalDictionary}
            />
            <Row mb>
              <Col xs={12} md={7}>
                <MatchMedia
                  defaultMinWidth={`${GridSettings.flexboxgrid.breakpoints.md}em`}
                >
                  {md =>
                    md ? (
                      <>
                        <SeeMoreBox minHeight={1000}>
                          <Col1Content
                            scrollInfo={scrollInfo}
                            sections={sections}
                          />
                        </SeeMoreBox>
                        <Separator />
                        <Divisor />
                      </>
                    ) : (
                      <Col1Content
                        scrollInfo={scrollInfo}
                        sections={sections}
                      />
                    )
                  }
                </MatchMedia>
              </Col>
              <Col xs={12} xsOffset={0} mdOffset={1} md={4}>
                <Row>
                  <Col xs={12}>
                    <H2>{staticInfo.metadata.col2Titulo}</H2>
                    {staticInfo.metadata.outrasDoencas.map((item, index) => (
                      <React.Fragment key={index}>
                        <Link to={`${localPath}/${item.slug}`}>
                          <H3>{item.title}</H3>
                          {index !==
                            staticInfo.metadata.outrasDoencas.length - 1 && (
                              <Divisor />
                            )}
                        </Link>
                      </React.Fragment>
                    ))}
                  </Col>
                  <Col xs={12} md={6}>
                    <Separator />
                    <Button
                      id='button-text-disease'
                      kind='secondary'
                      expanded
                      to={localPath}
                    >
                      {staticInfo.metadata.col2BotaoTexto}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <LargeSeparatorRow />
          </Grid>
        </IndexLayout>
      </ClientDoctorSelectContext.Provider>
    )
  }
}

export default DiseaseDetailTemplate
interface Col1ContentProps {
  scrollInfo: ScrollInfo[]
  sections: SectionInfo[]
}

const Col1Content: React.FunctionComponent<Col1ContentProps> = props => (
  <>
    {props.scrollInfo.length !== 0 && (
      <>
        <H2>Tópicos presentes nesta página: </H2>
        <TableOfContents scrollInfo={props.scrollInfo} />
      </>
    )}

    {props.sections.map(section => (
      <>
        <H2 id={section.id}>{section.title}</H2>
        <BodyDiseaseContent dangerouslySetInnerHTML={{ __html: section.content }} />
      </>
    ))}
  </>
)

export const query = graphql`
  query DiseaseDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsManualDeDoencas(slug: { eq: $slug }) {
      id
      title
      content
      modified_at
      metafields {
        key
        title
      }
      metadata {
        causasSintomas
        examesDiagnosticos
        tratamentosPrevencoes
        informacoesGestantes
        outrasInformacoes
        seo {
          titulo
          descricao
          imagem {
            url
            imgix_url
          }
          imagemAlternativa
        }
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "detalhe-do-manual-de-doencas" }) {
      metadata {
        col2Titulo
        col2BotaoTexto
        outrasDoencas {
          title
          slug
          type_slug
        }
      }
    }
  }
`
